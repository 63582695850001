@import "../bower_components/normalize.css/normalize";
@import "bootstrap/bootstrap";

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 100;
  src: local('ProximaNova'), local('ProximaNova'),
  url('/fonts/ProximaNovaThin.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: local('ProximaNova'), local('ProximaNova'),
  url('/fonts/ProximaNovaLight.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: local('ProximaNova'), local('ProximaNova'),
  url('/fonts/ProximaNovaRegularIt.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 500;
  src: local('ProximaNova'), local('ProximaNova'),
  url('/fonts/ProximaNovaSemibold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova'), local('ProximaNova'),
  url('/fonts/ProximaNovaBold.ttf') format('truetype');
}

.row{
  &:after, &:before{
    display: inline-block;
  }
}

.clear{
  clear: both;
}
*{
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: 'ProximaNova';
  font-weight: 400;
}
a,button{
  &:hover{
    text-decoration: none;
  }
}
input:focus{
  &::placeholder{
    opacity: 0;
  }
}
button:focus,button:active,input:focus,input:active{
  outline: none!important;
}
button.slick-arrow{
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 0;
  height: 0;
}
.slick-slide:focus{
  outline: none;
}
.container{
  max-width: 1400px;
  padding-left: 30px;
  padding-right: 30px;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background: #333;
}
body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #e6f0fa;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}

h1,h2,h3,h4,h5{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  display: inline-table;
  margin: 0;
  line-height: 1.3;
  font-size: 24px;
}

.btn-default{
  display: inline-table;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #0053A5;
  color: #0053A5!important;
  font-weight: 600;
  font-size: 14px;
  background: transparent;
  &.blue{
    background: #0053A5;
    color: #fff!important;
  }
  &.transparent{
    border-color: transparent;
    color: #262C40!important;
  }
  &.red{
    background: #F8A872;
    color: #3E1A1A!important;
    border-color: #F8A872;
    img{
      display: inline-table;
      height: 20px;
      vertical-align: middle;
      margin: 0 10px 0 0;
    }
  }
  &.green{
    border-color: transparent;
    background: #56931D;
    color: #fff!important;
  }
  &.whiteGreen{
    border-color: transparent;
    background: #ECF2E9;
    color: #56931D!important;
  }
}
a.readMore{
  display: inline-table;
  font-weight: 500;
  color: #0066CC;
}
span.miniArrow{
  display: inline-table;
  font-size: 26px;
  line-height: 0.3;
  vertical-align: middle;
}
.dropdown{
  display: inline-table;
  margin: 0;
  button.btn{
    display: table;
    color: #0066CC;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    font-weight: 500;
    &:focus{
      color: #0066CC;
      background: transparent;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle{
  color: #0066CC;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
  color: #0066CC;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
}
.paginationBlock{
  display: table;
  .pagination{
    display: table;
    margin: 0;
    li{
      display: inline-table;
      margin: 0 8px 0 0;
      vertical-align: middle;
      &:last-child{
        margin-right: 0;
      }
      span{
        display: table;
        margin: 0;
        color: #fff;
        border: 1px solid #004A95!important;
        background: #004A95!important;
        padding: 5px 11px;
        border-radius: 2px;
      }
      a,button{
        display: table;
        margin: 0;
        color: #ccc;
        border: 1px solid transparent;
        background: transparent;
        padding: 5px 10px;
        border-radius: 2px;
        &:hover{
          border-color: #ccc;
        }
        &.arrows{
          padding: 7px 10px;
          font-size: 28px;
          border-color: #ccc;
          color: #0066CC;
          line-height: 0.4;
          opacity: 0.5;
          &:hover{
            opacity: 1;
          }
        }
      }
      button.arrows{
        padding-bottom: 10px;
      }
    }
  }
}
header{
position: relative;
  background: #fff;
  width: 100%;
  overflow: hidden;
  .container{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  a.logo{
    display: inline-table;
    margin: 0;
    width: 25%;
    vertical-align: middle;
    img{
      display: table;
      height: 40px;
    }
  }
  ul.menu{
    display: inline-table;
    margin: 0 20px 0 0;
    vertical-align: middle;
    li{
      display: inline-table;
      margin: 0 35px 0 0;
      &:last-child{
        margin: 0;
      }
      a{
        display: table;
        margin: 0;
        color: #262C40;
        font-weight: 500;
      }
    }
  }
  ul.socials{
    display: inline-table;
    margin: 0;
    text-align: right;
    vertical-align: middle;
    li{
      display: inline-table;
      margin: 0 5px;
      a{
        display: table;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        margin: 0;
        background: #E6F0FA;
        text-align: center;
        i{
          display: table-cell;
          vertical-align: middle;
          color: #3976BB;
        }
      }
    }
  }
  ul.control{
    display: table;
    width: 105%;
    margin-left: -5%;
    text-align: right;
    margin-bottom: 0;
    li{
      display: inline-table;
      vertical-align: middle;
      margin: 0 0 0 27px;
      p{
        margin: 0;
      }
      .share{
        span{
          display: inline-table;
          vertical-align: middle;
          margin: 0 12px 0 0;
          font-weight: 500;
        }
        a{
          display: inline-table;
          vertical-align: middle;
          width: 30px;
          height: 30px;
          border-radius: 3px;
          margin: 0;
          background: #E6F0FA;
          text-align: center;
          i{
            display: table-cell;
            vertical-align: middle;
            color: #3976BB;
          }
        }
      }
      .call{
       display: table;
        margin: 0;
        span{
          display: table;
          margin: 0;
          font-size: 12px;
          font-weight: 300;
          color: #A4A4A4;
        }
        a{
          display: table;
          margin: 0;
          font-weight: 600;
          color: #333;
        }
      }
      a.langSwitcher{
        display: table;
        color: #333;
        font-weight: 600;
        i{
          display: inline-table;
          margin: 0 0 0 5px;
          color: #0066CC;
          vertical-align: middle;
        }
      }
      .btn-default{
        margin: 0;
        border: none;
        padding: 10px 12px;
        font-size: 14px;
        &.blue{
          img{
            display: inline-table;
            margin: 0 10px 0 0;
            height: 14px;
          }
          background: #0066CC;
        }
      }
    }
  }

  .blueBlock{
    position: relative;
    padding: 0;
    background: #0A4D83;
    .container{

    }
    .menu2{
      display: table;
      margin: 0;
      li{
        display: inline-table;
        margin: 0 28px 0 0;
        a{
          display: table;
          margin: 0;
          color: #fff;
        }
      }
    }
    form{
      display: table;
      margin: -10px 0;
      position: relative;
      width: 100%;
      input{
        background: #195592;
        width: 100%;
        border-radius: 3px;
        display: table;
        margin: 0;
        padding: 10px 16px;
        vertical-align: middle;
        font-weight: 300;
        border: none;
        color: #fff;
        &::placeholder{
          color: #fff;
          font-weight: 300;
        }
      }
      button{
        position: absolute;
        right: 15px;
        top: 50%;
        height: 20px;
        margin-top: -10px;
        border: none;
        background: transparent;
        padding: 0;
        z-index: 2;
        img{
          height: 20px;
        }
      }
    }
  }
}

.fatherBlock{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.container-fluid.default{
  position: relative;
  padding: 0;
}
.slide{
  background: #F6F6F9;
  .container{
    position: relative;
    padding-top: 70px;
    padding-bottom: 60px;
  }
  img.slideImg{
    position: absolute;
    left: 52%;
    bottom: 0;
    height: 95%;
    object-fit: contain;
    object-position: bottom right;
  }
  h1{
    font-size: 24px;
    display: table;
    margin: 0 0 25px;
  }
  p.descr{
    display: table;
    font-size: 16px;
    margin: 0 0 40px;
    line-height: 1.6;
  }
  .btn-default{
    margin: 28px 10px 0 0;
  }
}

.organizationsBlock{
  .container{
    padding-top: 80px;
    padding-bottom: 60px;
  }
    h2{
      margin: 0 0 12px 0;
    }
    p.descr{
      display: table;
      margin: 0 0 28px;
    }
    form{
      display: inline-table;
      margin: 0 0 34px;
      input, select, button{
        display: inline-table;
        margin: 0 10px 0 0;
        vertical-align: middle;
        padding: 10px 18px;
        border: 1px solid;
        width: auto;
        border-radius: 3px;
      }
      input{
        background: transparent;
        border-color: #eee;
        color: #333;
        min-width: 240px;
        &::placeholder{
          font-weight: 300;
        }
      }
      select{
        border-color: #9F9F9F;
        background: transparent;
        color: #333;
        font-weight: 500;
      }
      button{
        margin-right: 0;
        background: #0066CC;
        color: #fff;
        border-color: #0066CC;
        font-weight: 600;
      }
    }
    .openMap{
      float: right;
      display: inline-table;
    }
    p.name{
      display: inline-table;
      margin: 0 22px 0 0;
      font-weight: 600;
    }
    .paginationBlock{
      float: right;
    }

    .organizationsList{
      width: 100%;
      margin: 20px 0 30px;
      display: table;
      .item{
        display: table;
        width: 100%;
        margin: 0;
        padding: 10px 24px;
        border-radius: 4px;
        &:nth-child(odd){
          background: #F6F6F9;
        }
        ul{
          display: table;
          width: 100%;
          margin: 0;
          li{
            display: inline-table;
            margin: 0;
            vertical-align: middle;
            &:first-child{
              width: 1%;
            }
            &:nth-child(2){
              width: 17%;
            }
            &:nth-child(3){
              width: 20%;
            }
            &:nth-child(4){
              width: 12%;
            }
            &:nth-child(5){
              width: 20%;
            }
            &:nth-child(6){
              width: 12.5%;
            }
            &:nth-child(7){
              width: 12.5%;
            }
            &:nth-child(8){
              width: 5%;
            }
            img{
              display: table;
              margin: 0 auto;
              height: 30px;
              width: 80%;
              object-fit: contain;
              object-position: center;
            }
            p,a{
              margin: 0;
            }
            p{
              max-width: 80%;
              &.green{
                color: #76C32E;
                font-weight: 600;
                margin: 0 auto;
              }
              &.number{
                font-weight: 600;
                color: #01417F;
              }
            }
            a{
              display: table;
              width: 80%;
              color: #0053A5;
            }
          }
        }
      }
    }
    .btn-default{
      margin: 0 18px 0 0;
    }
}

.newMembers{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  a.readMore{
    margin-left: 40px;
  }
  h2{
    margin-bottom: 24px;
  }
  .paginationBlock{
    float: right;
  }
  .newOrganizationsBlock{
    display: table;
    width: 100%;
    border-top: 1px solid #E8E8E8;
    margin: 22px 0 0;
    .item{
      display: inline-table;
      width: 50%;
      position: relative;
      height: 85px;
      vertical-align: top;
      max-height: 85px;
      border-bottom: 1px solid #eee;
      ul{
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        margin: 0;
        li{
          display: inline-table;
          margin: 0;
          vertical-align: middle;
          &:first-child{
            width: 2%;
          }
          &:nth-child(2){
            width: 13%;
          }
          &:nth-child(3){
            width: 30%;
          }
          &:nth-child(4){
            width: 35%;
          }
          &:nth-child(5){
            width: 10%;
          }
          p.number{
            display: table;
            font-weight: 600;
            font-size: 14px;
            color: #01417F;
          }
          img.logotype{
            display: table;
            width: 75%;
            height: 34px;
            object-fit: contain;
            object-position: center;
            margin: 0 auto;
          }
          p,a{
            margin: 0;
            width: 90%;
            font-size: 12px;
          }
          a{
            color: #001B35;
            display: table;
            text-decoration: underline;
          }
        }
      }
    }
  }
}


.supportBlock{
  .container{
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -2.5%;
      width: 105%;
      height: 100%;
      background: #F6F6F9;
      z-index: 0;
    }
    .row{
      position: relative;
      z-index: 1;
    }
  }
  h3{
    margin: 0 0 45px;
  }
  form{
    display: table;
    width: 100%;
    margin: 0;
    .inputBlock{
      display: inline-table;
      width: 30%;
      vertical-align: top;
      position: relative;
      margin-right: 18px;
      label{
        display: table;
        margin: 0 0 10px;
        color: #001B35;
        font-weight: 600;
      }
      input, select, button{
        display: inline-table;
        margin: 0;
        vertical-align: middle;
        padding: 10px 12px;
        border: 1px solid;
        width: 100%;
        border-radius: 3px;
      }
      input{
        background: transparent;
        border-color: #e1e1e1;
        color: #333;
        &::placeholder{
          font-weight: 300;
        }
      }
      select{
        border-color: #e1e1e1;
        background: transparent;
        color: #333;
        font-weight: 500;
        width: 100%;
      }
      button{
        margin-right: 0;
        background: #0066CC;
        color: #fff;
        border-color: #0066CC;
        font-weight: 600;
      }
      p.regulations{
        font-size: 12px;
        margin-top: 4px;
        width: 120%;
        a{
          font-size: 12px;
        }
      }
      &.topper{
        margin-top: 40px;
      }
    }
  }
  .bigName{
    font-size: 22px;
    font-family: Montserrat,sans-serif;
    font-weight: 800;
    display: table;
    margin: 0 0 30px;
    line-height: 1.3;
  }
  .descr{
    display: table;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  .btn-default{
    font-size: 14px;
    img{
      margin-right: 8px;
    }
  }
}

.whyRegisterBlock{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h3{
    display: table;
    height: 62px;
    margin: 0 0 22px;
  }
  p.descr{
    display: table;
    width: 90%;
    line-height: 1.5;
    margin: 0 0 30px;
  }
  .problemBlock{
    display: table;
    width: 100%;
    position: relative;
    margin: 0 0 28px;
    .imgBlock{
      display: inline-table;
      width: 70px;
      height: 70px;
      border-radius: 24px;
      background: #305CD2;
      text-align: center;
      vertical-align: top;
      img{
        display: table;
        margin: 13px auto 0;
        height: 44px;
      }
    }
    .content{
      display: inline-table;
      width: 70%;
      vertical-align: top;
      margin: 0 0 0 20px;
      .name{
        display: table;
        margin: 0 0 5px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        color: #0066CC;
      }
      .descr{
        display: table;
        width: 90%;
        line-height: 1.5;
        margin: 0;
        font-size: 12px;
      }
    }
  }
}

.helperBlock{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .readMore{
    margin-left: 40px;
  }





}
.rightBlock{
  text-align: right;
  margin-bottom: 25px;
}
p.status{
  display: inline-table;
  margin: 0 0 0 18px;
  padding: 10px 18px;
  background: #E6E6F3;
  border-radius: 4px;
  font-size: 16px;
  span{
    display: inline-table;
    margin: 0 0 0 10px;
    font-weight: 600;
    color: #333;
    font-size: 16px;
  }
}
.helpBlock{
  display: table;
  width: 100%;
  position: relative;
  border-radius: 1px;
  background: #F6F6F9;
  p.date{
    position: absolute;
    right: 20px;
    top: 25px;
    font-weight: 600;
    font-size: 12px;
    color: #9CA7B1;
    span{
      font-weight: 600;
      color: #444;
      &.active{
        color: #76CC27;
      }
    }
  }
  img.bkg{
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    height: 60%;
    width: 50%;
    object-fit: contain;
    object-position: bottom right;
  }
  .content{
    display: table;
    width: 100%;
    padding: 20px;
    position: relative;
    z-index: 1;
    p{
      display: table;
      margin: 0 0 12px;
      font-size: 12px;
      line-height: 1.6;
      min-height: 20px;
      span{
        display: inline-table;
        font-weight: 600;
        color: #333;
        margin: 0 0 0 5px;
        font-size: 12px;
        &.tag{
          padding: 4px 8px;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          background: #ccc;
          color: #fff;
          &.blue{
            background: #62BDF3;
          }
          &.green{
            background: #9ED16F;
          }
          &.red{
            background: #FE875E;
          }
        }
      }
      b{
        display: inline-table;
        font-weight: 600;
        color: #333;
        margin: 0;
        font-size: 12px;
      }
      img{
        display: inline-table;
        height: 30px;
        width: 80px;
        object-fit: contain;
        object-position: left center;
        margin: 0 0 0 5px;
      }
    }
    a.more{
      display: table;
      margin: 25px 0 0;
      color: #0066CC;
    }
  }
  &.newHelp{
    img.bkg{
      filter: grayscale(100%);
    }
    a.more{
      margin: 55px 0 0;
    }
  }
}

.faqBlock{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h4{
    margin: 0 30px 0 0;
  }
  .btn-default.blue{
    margin-right: 10px;
  }
  #accordion{
    display: table;
    margin: 40px 0 0;
    width: 100%;
    .card{
      display: table;
      width: 100%;
      border: 1px dashed #C1C1C1;
      padding: 20px 25px;
      margin: 0 0 25px;
      background: #fff;
      .card-header,.card-body{
        border: none;
        margin: 0;
        padding: 0;
        background: transparent;
      }
      .card-header{
        p{
          padding: 0;
          margin: 0;
          border: 0;
          background: transparent;
          display: table;
          width: 100%;
          text-align: left;
          button{
            padding: 0;
            margin: 0;
            border: 0;
            background: transparent;
            color: #0066CC;
            font-size: 18px;
            font-weight: 600;
            display: table;
            width: 100%;
            position: relative;
            text-align: left;
            i{
              float: right;
              font-size: 22px;
            }
          }
        }
      }
      .card-body{
        line-height: 1.6;
        padding-top: 15px;
        width: 60%;
        max-width: 100%;
      }
      &.active{
        border-color: #0066CC;
        background: #F9F9FF;
        button{
          i{
          transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.newsBlock{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h4{
    margin-bottom: 20px;
  }
  ul.newsTabs{
    display: table;
    margin: 0 0 30px;
    border-bottom: 2px solid #E8E8E8;
    width: 100%;
    li{
      display: inline-table;
      margin: 0 26px 0 0;
      a{
        display: table;
        margin: 0 0 -2px;
        padding: 0 0 14px;
        transition: .2s linear;
        color: #001B35;
        font-size: 16px;
        border-bottom: 2px solid transparent;
        &.active{
          font-weight: 600;
          color: #0066CC;
          border-bottom: 2px solid #0066CC;
        }
      }
    }
  }
  .newsSlick{
    width: 102%;
    margin-left: -1%;
    margin-bottom: 28px;
    .slick-slide{
      margin: 0 10px;
      .block{
        display: table;
        width: 100%;
        background: #F6F6F9;
        border-radius: 3px;
        padding: 20px 24px 24px;
        position: relative;
        .date{
          display: table;
          font-size: 20px;
          font-weight: 800;
          margin: 0 0 18px;
          span{
            display: inline-table;
            vertical-align: top;
            font-weight: 400;
            font-size: 12px;
            color: #333;
            margin: 0 0 0 5px;
          }
        }
        .name{
          display: block;
          height: 18px;
          overflow: hidden;
          margin: 0 0 10px;
          font-weight: 600;
          color: #000;
        }
        .descr{
          display: block;
          margin: 0;
          height: 90px;
          overflow: hidden;
          line-height: 1.6;
        }
      }
    }

  }
  .paginationBlock{
    float: right;
    margin-top: -7px;
    ul.pagination{
      li{
        margin: 0 5px;
      }
    }
  }
}

.ourPartners{
  .container{
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .readMore{
    margin-left: 40px;
  }
  .partners{
    margin-top: 50px;
    .block{
      display: table;
      width: 100%;
      position: relative;
      img{
        display: table;
        margin: 0 auto 22px;
        height: 65px;
        max-width: 70%;
        object-fit: contain;
      }
      p{
        display: table;
        margin: 0 auto;
        line-height: 1.6;
        color: #A8A8A8;
        width: 80%;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}

footer{
  background: #004386;
  .container{
    padding-top: 60px;
    padding-bottom: 60px;
    img.logo2{
      display: table;
      width: 65%;
    }
    ul{
      display: table;
      margin: 0;
      li{
        display: table;
        margin: 0 0 18px;
        a{
          display: table;
          margin: 0;
          color: #fff;
        }
      }
    }
    button.share{
      background: #195592;
      border-radius: 3px;
      display: table;
      margin: 0 0 18px;
      padding: 8px 16px;
      border: none;
      text-align: left;
      min-width: 70%;
      color: #fff;
      span{
        display: inline-table;
        margin: 0;
        vertical-align: middle;
        font-weight: 400;
      }
      img{
        display: inline-table;
        margin: 0 0 0 10px;
        vertical-align: middle;
      }
    }
    form{
      display: table;
      margin: 0 0 18px;
      position: relative;
      width: 70%;
      input{
        background: #195592;
        width: 100%;
        border-radius: 3px;
        display: table;
        margin: 0;
        padding: 10px 16px;
        vertical-align: middle;
        font-weight: 300;
        border: none;
        color: #fff;
        &::placeholder{
          color: #fff;
          font-weight: 300;
        }
      }
      button{
        position: absolute;
        right: 15px;
        top: 50%;
        height: 20px;
        margin-top: -10px;
        border: none;
        background: transparent;
        padding: 0;
        z-index: 2;
        img{
          height: 20px;
        }
      }
    }
    a.authButton{
      display: inline-table;
      margin: 0 12px 0 0;
      color: #fff;
      font-weight: 500;
      &.login{
        margin: 0;
        color: #0095FF;
      }
    }
  }
  .container-fluid.foot{
    background: #00366C;
    padding: 0;
    .container{
      padding-top: 20px;
      padding-bottom: 20px;
      .siteCopyr{
        display: table;
        margin: 0;
        span{
          font-weight: 300;
          color: #fff;
          margin: 0 15px 0 0;
          font-size: 14px;
        }
        img{
          display: inline-table;
          margin: 0;
          height: 26px;
        }
        &.realCopyright{
          float: right;
        }
      }
    }
  }
}


/*In Pages*/

.breadCrumbs{
  .container{
    padding-top: 30px;
    padding-bottom: 30px;
    ul{
      display: table;
      margin: 0;
      li{
        display: inline-table;
        margin: 0 50px 0 0;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          right: -35px;
          width: 20px;
          top: 50%;
          border-bottom: 1px solid #ABABAB;
        }
        a{
          display: table;
          color: #ABABAB;
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
      }
    }
  }
}

.pageTitle{
  border-bottom: 1px solid #eee;
  .container{
    padding-top: 20px;
    padding-bottom: 30px;
    h1{
      font-size: 28px;
    }
  }
}
.organizationDrop{
  border: 1px solid #E9E9E9;
  border-radius: 3px;
  padding: 10px 20px;
  margin: -10px 0 0;
  button{
    color: #333!important;
  }
}
.organizationsInBlock{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  p.bigName{
    display: table;
    font-size: 18px;
    font-weight: 600;
    color: #002953;
  }

  .organizationBigBlock{
    display: table;
    width: 100%;
    position: relative;
    border-radius: 4px;
    background: #F6F6F9;
    border: 1px solid #F6F6F9;
    transition: .2s linear;
    padding: 20px 10px 20px 20px;
    margin: 0 0 25px;
    &:last-child{
      margin-bottom: 45px;
    }
    &:hover{
      border-color: #EFAC7F;
    }
    img.logotype{
      display: table;
      width: 85%;
      height: 40px;
      object-fit: contain;
      object-position: left center;
    }
    p.name{
      display: table;
      font-size: 16px;
      color: #0053A5;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      span{
        display: inline-table;
        vertical-align: middle;
        padding: 8px 14px;
        font-size: 12px;
        border-radius: 3px;
        background: #EBEBFF;
        margin: 0 0 0 10px;
        &.tagNew{
          background: #76C32E;
          color: #fff;
        }
      }
    }
    ul{
      display: table;
      margin: 0 0 15px;
      li{
        display: inline-table;
        margin: 0 10px 0 0;
        p{
          display: table;
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          a{
            font-size: 12px;
            color: #0066CC;
            text-decoration: underline;
          }
          span{
            font-size: 12px;
            color: #333;
            font-weight: 600;
          }
        }
        &:last-child{
          margin: 0;
        }
      }
    }
    p.missionText{
      display: table;
      line-height: 1.6;
      margin: 0 0 28px;
      font-size: 12px;
    }
    .btn-default{
      font-size: 14px;
      padding: 10px 15px;
      margin: 0 10px 0 0;
      &.blue{
        margin: 0;
      }
    }

    .greyText{
      display: table;
      background: #E6E6F3;
      border-radius: 3px;
      padding: 12px 18px;
      color: #333;
      width: 100%;
      span{
        font-weight: 500;
        &.green{
          color: #76C32E;
        }
        &.blue{
          color: #0066CC;
        }
      }
    }
    .miniInfo{
      display: table;
      font-size: 12px;
      color: #0066CC;
      margin: 0 auto;
    }
    a.miniInfo{
      text-decoration: underline;
    }
    .miniStatusBlock{
      max-width: 11%;
      width: 11%;
      flex: 0 0 11%;
      padding: 0;
    }
    .bigStatusBlock{
      max-width: 22%;
      width: 22%;
      flex: 0 0 22%;
      padding-left: 10px;
    }

    .btn-default.red{
      float: right;
    }
    &.humanInfo{
      img.logotype{
        width: 90%;
        height: auto;
        max-height: 150px;
        border-radius: 3px;
      }
      .infoBlock{
        p.name{
          margin: 0 0 20px;
        }
        p{
          display: table;
          margin: 0 0 20px;
          max-width: 90%;
          a{
            text-decoration: underline;
          }
          span{
            font-weight: 600;
          }
        }
      }
      .greyText{
        text-align: center;
      }
      .btn-default.blue{
        font-size: 14px;
        width: 100%;
        text-align: center;
        font-weight: 400;
      }
      .rightBlock{
        margin-bottom: 0;
      }
    }
  }

  .rightBlock{
    .btn-default.blue{
      margin-right: 10px;
    }
  }


  .redContentBlock{
    display: table;
    width: 100%;
    padding: 30px;
    border: 1px solid #EFAC7F;
    background: #FFFAED;
    border-radius: 4px;
    .name,.descr{
      font-weight: 600;
      color: #DD854B;
      font-size: 16px;
    }
    p.name{
      display: inline-table;
      margin: 12px 20px 0 0;
      vertical-align: middle;
    }
    p.descr{
      display: inline-table;
      margin: 12px 0 0;
      vertical-align: middle;
    }
    .dropdown{
      display: inline-table;
      margin: 0;
      vertical-align: middle;
      border: 1px solid #DD854B!important;
      padding: 10px 15px!important;
      border-radius: 4px!important;
      button{
        color: #DD854B!important;
      }
    }
    p.heart{
      display: inline-table;
      vertical-align: middle;
      margin: 0 0 0 30px;
      img{
        display: inline-table;
        vertical-align: middle;
        margin: 0 10px 0 0;
        height: 40px;
      }
      span{
        display: inline-table;
        vertical-align: middle;
        text-align: left;
        color: #C07E51;
        font-weight: 600;
        b{
          display: table;
          font-weight: 400;
        }
      }
    }
    button.red{
      color: #fff!important;
      background: #DD854B;
      margin: 0 0 0 30px;
    }
    .rightBlock{
      display: inline-table;
      margin: 0;
      float: right;
      vertical-align: middle;
    }
  }
}

.siteBarFilter{
  display: table;
  width: 100%;
  position: relative;
  margin-top: 35px;
  form{
    display: table;
    width: 110%;
    input{
      &.normal{
        display: table;
        width: 100%;
        padding: 10px 12px;
        border: 1px solid #eee;
        border-radius: 3px;
        color: #333;
        margin: 0;
      }
      &.mbot{
        margin-bottom: 26px;
      }
    }
    p.text{
      line-height: 1.4;
      font-size: 12px;
      margin: 15px 0;
    }
    .searchText{
      display: inline-table;
      max-width: 50%;
      padding-left: 15px;
      font-size: 12px;
      margin: 0;
      vertical-align: middle;
    }
  }
  .siteBarList{
    display: table;
    border: 1px solid #e9e9e9;
    padding: 14px;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    transition: .2s linear;
    p.categoryName{
      width: 100%;
      display: table;
      color: #333;
      font-weight: 600;
      margin: 0;
      position: relative;
      i{
        float: right;
        vertical-align: middle;
        margin: 3px -5px 0 0;
        color: #0066CC;
        font-size: 10px;
        display: inline-table;
      }
    }
    .listBlock{
      display: table;
      width: 100%;
      padding: 6px 0 0;
      display: none;
      p.grey{
        display: table;
        width: 100%;
        font-size: 12px;
      }
      .content{
        display: table;
        width: 100%;
        margin: 0 0 10px;
        input{
          display: inline-table;
          margin: 0 14px 0 0;
          vertical-align: top;
        }
        label{
          display: inline-table;
          margin: 0;
          vertical-align: top;
          max-width: 80%;
          font-size: 12px;
          span{
            font-size: 10px;
            display: table;
          }
        }
        input,label{
          &:hover{
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
    &:hover{
      cursor: pointer;
      background: #eee;
    }
    &.active{
      background: #F6F6F9;
      border-color: #F6F6F9;
      p.categoryName{
        i{
          transform: rotate(180deg);
        }
      }
      .listBlock{
        display: table;
      }
    }
  }
}

.socialButtonGlobal{
    display: inline-table;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0;
    background: #E6F0FA;
  text-decoration: none!important;
    text-align: center;
    i{
      display: table-cell;
      vertical-align: middle;
      color: #3976BB;
    }
}
.greyText{
  display: inline-table;
  background: #E6E6F3;
  border-radius: 3px;
  padding: 12px 18px;
  color: #333;
  span{
    font-weight: 500;
    &.green{
      color: #76C32E;
    }
    &.blue{
      color: #0066CC;
    }
  }
  &.red{
    background: #F6D7D5!important;
    span{
      color: #9D0013;
    }
  }
}
.textContent{
  display: table;
  width: 100%;
  line-height: 1.6;
  font-size: 14px;
  margin: 0;
  *{
    line-height: 1.6;
    font-size: 14px;
  }
}

.organInfoBlock{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      border-radius: 8px;
      background: #FCFCFF;
    }
  }
  img.logotype{
    display: table;
    max-width: 100%;
    min-width: 80%;
    object-fit: contain;
  }
 h1{
   display: table;
   font-size: 18px;
   font-weight: 700;
   font-family: 'Montserrat', sans-serif;
   margin: 0 0 24px;
   color: #0053A5;
 }

  .miniStatusBlock{
    position: absolute;
    right: 45px;
    display: table;
    top: 0;
    p,a{
      display: inline-table;
      margin: 0 0 0 10px;
      vertical-align: middle;
    }
    .socialButtonGlobal{
      height: 40px;
      width: 40px;
    }
  }
  p{
    display: table;
    margin: 0 0 22px;
    color: #333;
    a{
      display: inline-table;
      color: #0066CC;
      text-decoration: underline;
      font-weight: 500;
      &:first-child{
        margin-left: 6px;
      }
      &.socialButtonGlobal{
        margin-right: 10px;
      }
    }
    span{
      display: inline-table;
      color: #333;
      font-weight: 600;
      margin-left: 6px;
    }
    &.category{
      color: #0053A5;
      text-decoration: underline;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
    }
    &.inline{
      display: inline-table;
      margin-right: 18px;
    }
  }
  .bigName{
    display: inline-table;
    margin: 0 0 20px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }
  .paginationBlock{
    float: right;
    display: inline-table;
    vertical-align: middle;
  }
  .reviewSlick{
    width: 105%;
    margin: 0 0 0 -5%;
    .slick-slide{
      .block{
        display: table;
        width: 100%;
        background: #F6F6F9;
        border-radius: 3px;
        padding: 20px;
        .name{
          display: table;
          font-weight: 600;
          font-size: 16px;
          margin: 0 0 14px;
        }

      }
    }
  }
}

.aboutFond{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .content{
    padding-top: 30px;
    width: 95%;
  }
  h2{
    margin-right: 20px;
  }
  .imgBlock{
    display: table;
    margin: 0 0 30px;
    position: relative;
    img{
      display: inline-table;
      width: 100px;
      height: 100px;
      border-radius: 3px;
      object-fit: cover;
      margin: 0 28px 0 0;
      vertical-align: middle;
    }
    p{
      display: inline-table;
      vertical-align: middle;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      margin: 0;
      font-size: 16px;
      a{
        display: table;
        font-family: 'Montserrat', sans-serif;
        color: #0082FF;
        margin: 5px 0 0;
        text-decoration: underline;
        font-size: 16px;
      }
    }
  }
  .blueContent{
    display: table;
    width: 100%;
    margin: 30px 0 0;
    padding: 20px 24px 45px;
    border-radius: 6px;
    background: #F4F9FF;
    p.name{
      font-family: 'Montserrat', sans-serif;
      display: table;
      font-weight: 700;
      margin: 0 0 10px;
      font-size: 18px;
    }
    .textContent{
      max-width: 90%;
    }
  }

  .projectsBlock{
    padding-top: 40px;
    p.name{
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-weight: 700;
      margin: 0 25px 0 0;
      display: inline-table;
    }
    .paginationBlock{
      float: right;
    }

    .projectSlick{
      width: 102%;
      margin: 22px 0 0 -1%;
      .slick-slide{
        margin: 0 5px;
      }
    }
  }

  .redContent{
    display: table;
    width: 105%;
    margin: 30px 0 0 -5%;
    padding: 20px 24px;
    border-radius: 6px;
    background: #FFF4ED;
   h2{
     margin: 0 0 22px;
   }
    p{
      display: table;
      margin: 0 0 18px;
      font-weight: 400;
      line-height: 1.6;
      span{
        font-weight: 600;
      }
    }
    form{
      display: table;
      width: 100%;
      margin: 0;
      .inputBlock{
        display: table;
        width: 100%;
        margin: 0 0 24px;
        span{
          display: table;
          font-weight: 700;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          margin: 0 0 10px;
        }
        input{
          font-size: 12px;
          &[type="radio"]{
            display: none;
          }
          &[type="text"]{
            display: inline-table;
            padding: 10px 12px;
            border-radius: 3px;
            border: 1px solid #888;
            color: #333;
            margin: 0 5px 0 0;
            background: transparent;
            &::placeholder{
              color: #333;
            }
          }
        }
        label{
          font-size: 12px;
          display: inline-table;
          padding: 10px 12px;
          border-radius: 3px;
          border: 1px solid #888;
          margin: 0 5px 0 0;
          color: #000;
          background: transparent;
        }
      }
      .btn-default.red{
        padding: 10px;
      }
    }
  }

  .grayContent{
    display: table;
    width: 105%;
    margin: 30px 0 0 -5%;
    padding: 20px 24px;
    border-radius: 6px;
    background: #F6F6F9;
   h3{
     margin: 0 0 22px;
   }
    p{
      display: table;
      margin: 0 0 10px;
      font-weight: 400;
      line-height: 1.6;
      span{
        font-weight: 600;
      }
      a{
        display: table;
        color: #0066CC;
        margin: 10px 0 0;
        &.socialButtonGlobal{
          display: inline-table;
          margin: 0 5px;
        }
      }
    }
  }

  .bottomContent{
    padding-top: 40px;
    h3{
      font-size: 18px;
      margin: 0 0 22px;
    }
    .galleryBlock{
      width: 105%;
      margin-left: -5%;
      .col-sm-3{
        padding: 0 5px;
      }
    }
    .map{
      display: table;
      width: 95%;
      height: 140px;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      background: #eee;
      margin: 0 0 22px;
    }

  }
}
.fondImg{
  display: table;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  text-align: center;
  img{
    display: table;
    width: 100%;
    position: relative;
    z-index: 0;
    height: 120px;
    object-fit: cover;

  }
  &.openGallery{
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.76);
      z-index: 1;
    }
    span{
      position: absolute;
      z-index: 3;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin: -10px 0 0 -15px;
      color: #333;
      font-weight: 800;
      font-size: 18px;
    }
  }
}

.inOrganizationsBlock{
  h4{
    margin-right: 35px;
  }
  .organizationsList{
    .item{
      padding: 18px 24px;
      ul{
        li{
          width: 20%!important;
          max-width: 20%!important;
          a{
            font-weight: 600;
            text-decoration: underline;
          }
          .name{
            display: table;
            width: 80%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.otherOrganizations{
  .container{
    padding-top: 40px;
    padding-bottom: 120px;
  }
  h4{
    margin-bottom: 40px;
  }
  .block{
    display: table;
    width: 100%;
    position: relative;
    img{
      display: inline-table;
      margin: 0;
      vertical-align: middle;
      width: 30%;
      height: 60px;
      object-fit: contain;
      object-position: top left;
    }
    p{
      display: inline-table;
      vertical-align: middle;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 16px;
      margin: 0;
      width: 70%;
      padding-left: 20px;
      color: #0053A5;
      span{
        display: table;
        color: #0053A5;
        font-weight: 300;
        font-size: 12px;
        width: 80%;
        margin: 5px 0 0;
      }
    }
  }
}

.accountMenu{
  border-bottom: 1px solid #e3e3e3;
  .container{
    padding-top: 40px;
    padding-bottom: 20px;
  }
  ul{
    display: table;
    margin: 0;
    li{
      display: inline-table;
      margin: 0 25px 0 0;
      a{
        display: table;
        margin: 0;
        font-size: 16px;
        color: #001B35;
      }
    }
  }
}


.tags{
  display: table;
  padding: 10px 15px;
  border-radius: 4px;
  background: #ccc;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  &.mini{
    font-size: 14px;
  }
  &.blue{
    background: #62BDF3;
  }
  &.green{
    background: #9ED16F;
  }
  &.red{
    background: #AB2B0E;
  }
  &.grey{
    background: #F3F3F3;
    color: #0057AE;
  }
}


.myOrganizationContent{
  .container{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .greyInfoBlock{
    background: #FCFCFF;
    position: relative;
    width: 100%;
    display: table;
    border-radius: 8px;
    padding: 30px;
    margin: 0 0 18px;
    &.mini{
      padding: 24px;
    }
    &.firstBig{
      padding: 60px 30px;
      img.logotype{
        display: table;
        max-width: 90%;
        margin: 0 auto;
      }
      h1{
        display: table;
        color: #0053A5;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        margin: 0 0 38px;
      }
      p.descr{
        display: table;
        font-family: 'Montserrat', sans-serif;
        margin: 0;
        span{
          font-family: 'Montserrat', sans-serif;
          margin: 8px 0 0;
          display: table;
        }
      }
    }


    .countTag{
      display: inline-table;
      padding: 6px 6px 6px 15px;
      border-radius: 4px;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      margin: 0 15px 0 0;
      background: #eee;
      span{
        display: inline-table;
        padding: 5px 18px;
        border-radius: 4px;
        background: #fff;
        color: #333;
        font-size: 16px;
        margin: 0 0 0 10px;
      }
      &.blue{
        background: #ECF5FF;
      }
      &.red{
        background: #FFC9A5;
        color: #9D3F00;
      }
      &.green{
        background: #CCE7B6;
        color: #56931D;
      }
    }


    .applicationBlock{
      display: table;
      width: 100%;
      position: relative;
      padding: 28px 0;
      border-top: 1px solid #eee;
      margin: 28px 0 0 ;
      .padLeft{
        padding-left: 65px;
      }
      .tags.default.blue{
        margin-bottom: 12px;
      }
      p{
        display: table;
        line-height: 1.4;
        margin: 0;
        &.name{
          font-weight: 600;
          margin: 10px 0 22px;
          &.center{
            margin-left: auto;
            margin-right: auto;
          }
        }
        span{
          font-weight: 500;
          color: #0095FF;
        }
      }
      .btn-default{
        margin-bottom: 12px;
        text-align: center;
        width: 100%;
        &.blue{
          margin: 0;
        }
      }
    }

    .btn-default.more{
      margin-top: 24px;
    }
    a.mini{
      display: table;
      width: 100%;
      margin: 10px 0 0;
      color: #00A0FF;
      font-size: 12px;
      text-decoration: underline;
    }
  }


  .greyContent{
    background: #FCFCFF;
    position: relative;
    width: 105%;
    display: table;
    border-radius: 8px;
    padding: 24px;
    margin: 0 0 18px -5%;
    .miniStatusBlock{
      margin-bottom: 18px;
    }
    p.date{
      display: inline-table;
      margin: 0 15px 0 0;
    }

    .content{
      display: table;
      width: 100%;
      margin: 18px 0 0;
      p{
        display: table;
        margin: 0 0 10px;
        span{
          display: inline-table;
          font-weight: 600;
          margin: 0 4px 0 0;
        }
      }
      a{
        display: table;
        margin: 15px 0;
        color: #0066CC;
        text-decoration: underline;
      }
    }
  }

  .settings{
    display: table;
    font-family: 'Montserrat', sans-serif;
    color: #0053A5;
    font-size: 20px;
    font-weight: 600;
    margin: 60px 0 0;
    img{
      display: inline-table;
      vertical-align: middle;
      margin: -10px 0 0 12px;
    }
  }
}

.projectPage{
  .container{
    &:before{
      display: none;
    }
  }

  h1{
    font-size: 22px;
    margin: 0 0 20px;
    font-weight: 500;
  }
  p.bigName{
    font-size: 16px;
  }
  img.logotype{
    display: table;
    max-width: 65%;
    margin: 0 0 30px;
  }

  .projectInfo{
    background: #FCFCFF;
    padding: 35px;
    border-radius: 6px;
    display: table;
    width: 99%;
    position: relative;
    .absol{
      position: absolute;
      right: 30px;
      top: 30px;
    }
    p{
      font-weight: 600;
      margin: 0 0 14px;
      span{
        font-weight: 400;
      }
      a{
        color: #0267FF;
        &:hover{
          cursor: pointer;
          color: #0267FF;
        }
      }
    }
  }
  .allReviews{
    margin-left: -5%;
    margin-top: 20px;
  }
  .ptojectContent{
    display: table;
    margin: 20px 0;
    width: 99%;
    padding: 35px;
    background: #F9F9FF;
    position: relative;
    border-radius: 6px;
    p.name{
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      margin: 0 0 15px;
    }
    p.descr{
      display: table;
      max-width: 90%;
      line-height: 1.8;
      margin: 0;
    }
  }

  .galleryBlock{
    width: 99%;
    display: table;
    margin-top: 50px;
    h3{
      font-size: 18px;
      margin: 0 0 20px;
    }
  }
}

.helpInProjectPage{
  .container{
    padding-bottom: 100px;
  }
  h4{
    font-size: 18px;
    margin-bottom: 24px;
  }
}

.myAccountPage{
  .accountInfo{
    img.avatar{
      display: table;
      width: 100%;
      height: 120px;
      object-fit: cover;
      object-position: center;
      border-radius: 6px;
    }
    p.name{
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-weight: 700;
      margin: 28px 0 10px;
    }
    p.descr{
      display: table;
      font-family: 'Montserrat', sans-serif;
      margin: 0;
    }
  }

  .accountGreyContent{
    p{
      display: table;
      padding: 14px 0;
      width: 100%;
      border-top: 1px solid #E4F0FF;
      margin: 0;
      span{
        display: inline-table;
        margin: 0;
        vertical-align: top;
        &:first-child{
          width: 40%;
          font-weight: 600;
        }
        &:last-child{
          width: 60%;
        }
        &.fullText{
          display: table;
          width: 100%;
          margin: 10px 0 0;
          line-height: 1.6;
        }
      }
    }
    p.big{
      display: inline-table;
      margin: 0 0 18px;
      font-weight: 600;
      color: #44589B;
      font-size: 14px;
      padding: 0;
      width: auto;
      border: none;
      vertical-align: middle;
    }
    a.settings{
      display: inline-table;
      float: right;
      margin: 0;
      font-family: 'ProximaNova';
      font-size: 14px;
      color: #0176FF;
      img{
        height: 16px;
        margin-top: -4px;
        margin-left: 5px;
      }
    }
  }
  .greyInfoBlock.mini{
    a.settings{
      display: inline-table;
      float: right;
      margin: 10px 0 0;
      font-family: 'ProximaNova';
      font-size: 14px;
      color: #0176FF;
      img{
        height: 16px;
        margin-top: -4px;
        margin-left: 5px;
      }
    }
    .applicationBlock{
      p.name{
        margin-top: 0;
      }
    }
  }
}

.accountMenu{
  h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    display: table;
    margin: 0 0 24px;
    font-weight: 600;
    color: #0053A5;
  }
  ul.myAccountMenu{
    li{
      a{
        color: #0053A5;
        padding: 8px 14px;
        border: 1px solid #0053A5;
        border-radius: 4px;
        font-size: 14px;
      }
    }
  }
}

.applicationSearchBlock{
  display: table;
  width: 100%;
  border-radius: 4px;
  padding: 20px 35px;
  margin: 0 0 25px;
  background: #EDF6FF;
  h1{
    margin-bottom: 15px;
  }
  form{
    display: table;
    width: 100%;
    margin: 0;
    .searchBlock{
      display: table;
      position: relative;
      margin: 0 0 24px;
      width: 100%;
      input{
       display: table;
        width: 100%;
        padding: 10px 24px;
        background: transparent;
        border-radius: 4px;
        color: #7299BE;
        border: 1px solid #77AFE8;
      }
      button{
        position: absolute;
        top: 4px;
        right: 4px;
        padding: 6px 15px;
        font-weight: 400;
      }
    }
    ul{
      display: table;
      width: 80%;
      margin: 0 0 15px;
      li{
        display: inline-table;
        margin: 0 15px 0 0;
        position: relative;
        input{
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          opacity: 0;
        }
        label{
          display: table;
          color: #00335B;
          font-weight: 500;
          padding: 6px 0;
        }
      }
    }
    .btn-default.transparent{
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      i{
        margin-left: 10px;
        color: #0066CC;
      }
      &:hover{
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .selectBlock{
    display: table;
    width: 100%;
    padding: 30px 0 10px;
    border-top: 1px solid #E0EBF7;
    margin: 0 0 25px;
    border-bottom: 1px solid #E0EBF7;
    display: none;
    .dropdown{
      display: inline-table;
      margin: 0 20px 20px 0;
      button{
        padding: 10px 20px!important;
        border-radius: 4px!important;
        border: 1px solid #004A95!important;
        &:focus{
          padding: 10px 20px;
          border-radius: 4px;
          border: 1px solid #004A95;
        }
      }
    }
    &.opened{
      display: table;
      padding: 0;
      margin: 0;
      border: none;
    }
  }
  .rangeBarBlock{
    display: table;
    width: 100%;
    padding-bottom: 20px;
    display: none;
    p.rangeName{
      display: table;
      margin: 0 0 15px;
    }
    .rangeBlock{
      display: inline-table;
      margin: 0 20px 0 0;
      vertical-align: middle;
    }
    .inputBlock{
      display: inline-table;
      margin: 0 20px 0 0;
      vertical-align: middle;
      input{
        display: inline-table;
        vertical-align: middle;
        margin: 0 10px 0 0;
        height: 20px;
        width: 20px;
      }
      label{
        display: inline-table;
        vertical-align: middle;
        margin: 0;
      }
    }
    .btn-default{
      vertical-align: middle;
      padding: 8px 25px;
    }
  }
}

.completeApplicationPage{
  .container{
    padding-bottom: 40px;
  }
  p.blueName{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    color: #0053A5;
    margin: 0;
    span{
      font-family: 'Montserrat', sans-serif;
      font-size: 22px;
      font-weight: 700;
    }
  }
  h1{
    margin: 30px 0;
  }
  .applicationGallery{
    display: table;
    width: 100%;
    margin: 0;
    .bigImage{
      display: inline-table;
      width: 80%;
      vertical-align: top;
      a{
        display: table;
        width: 100%;
        margin: 0;
        img{
          display: table;
          width: 103%;
          height: 350px;
          object-fit: cover;
          object-position: center;
          border-radius: 6px;
        }
      }
    }
    .galleryBlock{
      display: inline-table;
      width: 20%;
      vertical-align: top;
      a{
        display: table;
        width: 70%;
        margin: 0 0 25px;
        float: right;
        &:last-child{
          margin: 0;
        }
        img{
          display: table;
          width: 100%;
          height: 100px;
        }
      }
    }
  }
  .greyContent{
    display: table;
    width: 100%;
    margin: 25px 0 0;
    background: #F6F6F9;
    padding: 25px;
    border-radius: 6px;
    p.name{
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 18px;
      margin: 0 0 16px;
    }
    *{
      line-height: 1.8;
      display: table;
      margin: 0 0 15px;
    }
  }
  .share{
    display: table;
    margin: 24px 0 0;
    span{
      display: inline-table;
      vertical-align: middle;
      margin: 0 12px 0 0;
      font-weight: 500;
    }
    a{
      display: inline-table;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      border-radius: 3px;
      margin: 0;
      background: #E6F0FA;
      text-align: center;
      img{
        display: table;
        margin: 6px auto;
        height: 18px;
        vertical-align: middle;
        color: #3976BB;
      }
    }
  }
  .infoBlock{
    display: table;
    width: 100%;
    border-radius: 6px;
    padding: 20px 25px 5px;
    margin: 0 0 20px;
    border: 1px solid #CFCFFF;
    background: #F9F9FF;
    p{
      display: table;
      margin: 0 0 15px;
      span{
        font-weight: 600;
        margin: 0 6px 0 0;
        &.spanTable{
          display: table;
          margin: 0 0 8px;
        }
      }
      a{
        display: inline-table;
        margin: 0 3px 0 0;
        color: #0368FF;
        text-decoration: underline;
      }
      &.mbuts{
        a{
          margin: 0 15px 0 0;
          &.socialButtonGlobal{
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.resultOrganizations{
  padding-bottom: 80px!important;
  .block{
    display: table;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding: 20px 25px;
    background: #F9F9FF;
    img.logotype{
      display: inline-table;
      height: 60px;
      width: 40%;
      object-fit: contain;
      vertical-align: middle;
      object-position: left center;
    }
    img.good{
      display: table;
      position: absolute;
      right: 10px;
      top: 10px;
      height: 28px;
    }
    p.name{
      display: inline-table;
      max-width: 60%;
      vertical-align: middle;
      font-weight: 600;
      color: #0066CC;
      margin: 0;
    }
    .content{
      display: table;
      width: 100%;
      border-top: 1px solid #EDEDFF;
      padding: 20px 0 0;
      margin: 20px 0 0;
      position: relative;
      p{
        color: #58658E;
        display: table;
        margin: 0 0 12px;
        font-weight: 600;
        span{
          display: inline-table;
          font-weight: 400;
        }
      }
      .btn-default{
        position: absolute;
        right: 0;
        bottom: 10px;
        padding: 8px 15px;
      }
    }
    input.range{
        z-index: 25;
        width: 100%;
        -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
        background: transparent; /* Otherwise white in Chrome */

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
        }

        &:focus {
          outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }

        &::-ms-track {
          width: 100%;
          cursor: pointer;

          /* Hides the slider so custom styles can be added */
          background: transparent;
          border-color: transparent;
          color: transparent;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          box-shadow: none;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background: #fff;
          border:1px solid #0066CC;
          cursor: pointer;
          margin-top: -9px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
          position: relative;

        }
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 1px;
          cursor: pointer;
          box-shadow: none;
          background: #3797DB;
          border-radius: 3px;
          border: 1px solid transparent;
        }

    }
    .trackBlock{
      display: table;
      width: 100%;
      p{
        margin: 10px 0 0;
      }
      p.trackLeft{
        display: inline-table;
        width: 50%;
      }
      p.trackRight{
        display: inline-table;
        width: 50%;
        text-align: right;
      }
    }
  }
  .rightBlock{
    margin-bottom: 40px;
    select{
      background: transparent;
      color: #333;
      font-weight: 500;
      display: inline-table;
      margin: 0 0 0 10px;
      vertical-align: middle;
      padding: 10px 18px;
      border: 1px solid #6498CC;
      width: auto;
      border-radius: 3px;
    }
  }
}

@media(max-width: 1500px){

}

@media(max-width: 1025px){

}
@media(max-width: 678px){
  header ul.socials {
    display: none;
  }

  header ul.menu {
    display: none;
  }

  ul.mobileMenu {
    display: inline-table!important;
    margin: 0;
    vertical-align: middle;
    width: 60%;
    text-align: right;
  }

  ul.mobileMenu li {
    display: inline-table;
    vertical-align: top;
    margin: 0 0 0 10px;
  }

  ul.mobileMenu li a {
    background: #e6f0fa;
    width: 35px;
    height: 35px;
    border-radius: 4px;
    display: table;
    text-align: center;
  }

  ul.mobileMenu li a img {
    display: table;
    margin: 7px auto 0;
  }

  ul.mobileMenu li a.lang {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 9px;
    color: #0066CC;
  }

  header a.logo img {
    height: 30px;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  header .container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  header ul.control {
    display: none;
  }

  header .blueBlock .container {
    padding: 0 5px;
  }

  header .blueBlock form {
    display: none;
  }

  header .blueBlock .menu2 {
    display: block;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
  }

  header .blueBlock .menu2 li {
    display: inline-block;
    margin: 0 5px 0 0;
  }

  header .blueBlock .menu2::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    background: #333;
    display: none;
  }
  header .blueBlock .menu2::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #FF7200;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
    display: none;
  }

  header .blueBlock .menu2 li a {
    border-radius: 3px;
    padding: 8px 8px;
    background: #195C9F;
    margin: 5px 0 0;
  }
  header a.logo{
    width: 38%;
  }
  .breadCrumbs {
    display: none;
  }

  h1, h2, h3, h4, h5 {
    font-size: 18px;
  }

  .pageTitle .container h1 {
    font-size: 18px;
  }

  .pageTitle .container {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .siteBarFilter {
    display: none;
  }

  .organizationsInBlock p.bigName {
    display: none;
  }

  .organizationsInBlock .container {
    padding-top: 0;
  }

  .organizationDrop {
    margin: 0;
    display: none;
  }

  .rightBlock {
    margin: 0;
  }

  footer {
    position: relative;
    overflow: hidden;
  }

  .organizationsInBlock .organizationBigBlock {
    padding: 15px;
  }

  .organizationsInBlock .organizationBigBlock img.logotype {
    width: 35%;
  }
  .organizationsInBlock .organizationBigBlock p.name {
    position: absolute;
    top: -30px;
    right: 0;
    width: 50%;
    font-size: 14px;
    margin: 0;
  }

  .organizationsInBlock .organizationBigBlock p.name span {
    display: none;
  }

  .organizationsInBlock .organizationBigBlock .bigStatusBlock {
    display: none;
  }

  .organizationsInBlock .organizationBigBlock .miniStatusBlock {
    display: none;
  }

  .organizationsInBlock .organizationBigBlock .greyText {
    font-size: 12px;
    padding: 8px;
    display: inline-table;
    width: auto;
    margin: 16px 10px 10px 0;
  }

  .organizationsInBlock .organizationBigBlock .greyText span {
    font-size: 12px;
  }

  .organizationsInBlock .organizationBigBlock a.miniInfo {
    text-decoration: unset;
    display: table;
    margin: 0 0 10px;
  }

  .organizationsInBlock .organizationBigBlock .miniInfo {
    display: table;
    margin: 0 0 10px;
  }



  .organizationsInBlock .organizationBigBlock ul li {
    display: table;
    width: 100%;
    margin: 0 0 10px;
  }

  .organizationsInBlock .organizationBigBlock p.missionText {
    display: none;
  }

  .organizationsInBlock .organizationBigBlock .btn-default {
    padding: 8px;
    font-size: 12px;
  }

  .organizationsInBlock .organizationBigBlock .btn-default.blue {
    margin: 10px 0 0;
  }

  .organizationsInBlock .organizationBigBlock .btn-default.red {
    position: absolute;
    top: -76px;
    right: 0;
  }

  .btn-default.red img {
    margin: 0 5px 0 0;
    height: 16px;
  }

  .organizationsInBlock .organizationBigBlock .btn-default span.miniArrow {
    display: none;
  }

  .organizationsInBlock .organizationBigBlock ul.second {
    display: none;
  }
  .organizationsInBlock .organizationBigBlock {
    margin: 10px 0;
  }
  .organizationsInBlock .organizationBigBlock {
    margin: 10px 0;
  }

  .organizationsInBlock .paginationBlock {
    display: none;
  }

  .organizationsInBlock .organizationBigBlock:last-child {
    margin-bottom: 15px;
  }

  .organizationsInBlock .rightBlock .btn-default {
    font-size: 14px;
    display: table;
    width: 100%;
    margin: 0 0 10px;
  }

  .organizationsInBlock .container {
    padding-bottom: 10px;
  }
  footer .container {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  footer .container img.logo2 {
    width: 100%;
    height: 31px;
    margin: 0 auto 15px;
  }

  footer .container ul {
    width: 100%;
    display: table;
    margin: 0;
  }

  footer .container ul li {
    margin: 0 0 5px;
    width: 100%;
  }

  footer .container ul li a {
    width: 100%;
    padding: 10px;
    background: #003B76;
    text-align: center;
  }

  footer .container ul.mobileNone {
    display: none;
  }

  footer .container button.share {
    display: none;
  }

  footer .container form {
    display: none;
  }

  footer .container a.authButton {
    display: none;
  }

  footer .container-fluid.foot .container .siteCopyr {
    display: none;
  }

  footer .container-fluid.foot .container .siteCopyr.realCopyright {
    float: unset;
    display: table;
    width: 100%;
  }

  footer .container-fluid.foot .container .siteCopyr img {
    width: 31%;
    height: auto;
  }

  footer .container-fluid.foot .container .siteCopyr span {
    font-size: 14px;
  }
  button.openMobileMenu {
    display: table;
    width: 100%;
    padding: 10px;
    background: #003b76;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 3px;
    margin: 0 0 5px;
  }

  button.openMobileMenu i {
    margin-left: 5px;
    font-size: 10px;
  }

  footer .container ul.mobileToggle {
    display: none;
  }
  .mobileFilters ul {
    display: flex;
    width: 100%;
    margin: 0;
  }

  .mobileFilters {
    border-bottom: 1px solid #eee;
  }

  .mobileFilters ul li {
    display: inline-flex;
    flex-grow: 1;
    width: 50%;
    vertical-align: top;
    margin: 0;
  }

  .mobileFilters ul li button {
    display: table;
    width: 100%;
    margin: 0;
    padding: 12px 0;
    background: transparent;
    border: none;
  }

  .mobileFilters .container {
    padding: 0;
  }

  .mobileFilters ul li button img {
    display: inline-table;
    vertical-align: middle;
    margin: 0 10px 0 0;
  }

  .mobileFilters ul li button span {
    display: inline-table;
    vertical-align: middle;
    margin: 0;
  }

  .mobileFilters ul li:first-child {
    border-right: 1px solid #eee;
  }
  .organInfoBlock .container {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 95%;
    margin-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .organInfoBlock img.logotype {
    display: inline-table;
    width: 36%;
    min-width: auto;
    height: 60px;
    object-position: left center;
  }

  .organInfoBlock h1 {
    font-size: 14px;
    position: absolute;
    top: -45px;
    right: 15px;
    width: 60%;
  }

  .organInfoBlock p.category {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .organInfoBlock .miniStatusBlock {
    position: relative;
    right: unset;
    margin: 20px 0;
    width: 100%;
  }

  .organInfoBlock .miniStatusBlock a, .organInfoBlock .miniStatusBlock p {
    margin: 0 15px 0 0;
  }

  .organInfoBlock .miniStatusBlock a {
    float: right;
  }

  .organInfoBlock p {
    margin: 0 0 10px;
  }

  .organInfoBlock p a:first-child {
    margin: 0;
  }

  .organInfoBlock p a.socialButtonGlobal {margin: 0 5px;}

  .organInfoBlock .bigName {
    margin: 30px 0 0;
    font-size: 16px;
  }

  .organInfoBlock .paginationBlock {
    margin-top: 25px;
  }

  .organInfoBlock .reviewSlick {
    margin: 15px 0 0;
    width: 100%;
  }

  .organInfoBlock .reviewSlick .slick-slide .block {
    padding: 14px;
  }

  .organInfoBlock .reviewSlick .slick-slide .block .name {
    font-size: 14px;
  }

  .organInfoBlock p.textContent {
    margin: 0;
  }
  .aboutFond .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .aboutFond h2 {
    margin: 0 0 15px;
  }

  .aboutFond .btn-default {
    display: none;
  }

  .aboutFond .content {
    padding-top: 0;
    background: #FCFCFF;
    width: 100%;
    padding: 15px;
  }

  .aboutFond .imgBlock p {
    width: 50%;
    font-size: 14px;
  }

  .aboutFond .imgBlock p a {
    font-size: 14px;
  }

  .aboutFond .blueContent {
    padding: 15px;
    margin: 15px 0 0;
  }

  .aboutFond .projectsBlock {
    padding-top: 20px;
  }

  .aboutFond .projectsBlock p.name {
    font-size: 16px;
  }

  .aboutFond .projectsBlock a.readMore {
    float: right;
  }

  .aboutFond .projectsBlock .paginationBlock {
    margin: 20px 0 0;
  }

  .aboutFond .projectsBlock .projectSlick {
    margin-top: 5px;
  }
  button.btn-default.mobileOpenContent {
    width: 100%;
    margin: 0 0 10px;
    text-align: left;
    background: #E3EBF1;
    color: #333!important;
    padding: 10px 15px;
    font-weight: 500;
    border-color: transparent;
  }
  button.btn-default.mobileOpenContent i{
    float: right;
    font-size: 12px;
    margin: 5px 0 0;
  }
  button.btn-default.mobileOpenContent.active i{
    transform: rotate(180deg);
  }

  .aboutFond .textContent {
    display: none;
  }

  .aboutFond .blueContent .textContent {
    display: table;
  }

  .aboutFond .blueContent {
    display: none;
  }
  .textContent.openContentMobile {
    margin-bottom: 15px;
  }
  .aboutFond .imgBlock {
    margin-bottom: 15px;
  }
  .aboutFond .imgBlock {
    margin-bottom: 15px;
  }

  .projectSlick .helpBlock .content {
    background: #f6f6f9;
  }

  .helpBlock .content p {
    font-size: 13px;
  }

  .helpBlock .content p span {
    font-size: 13px;
  }

  .helpBlock .content p b {
    font-size: 13px;
    margin-right: 5px;
  }

  .helpBlock .content a.more {
    margin: 15px 0 0;
  }
  .aboutFond .imgBlock {
    margin-bottom: 15px;
  }

  .projectSlick .helpBlock .content {
    background: #f6f6f9;
  }

  .helpBlock .content p {
    font-size: 13px;
  }

  .helpBlock .content p span {
    font-size: 13px;
  }

  .helpBlock .content p b {
    font-size: 13px;
    margin-right: 5px;
  }

  .helpBlock .content a.more {
    margin: 15px 0 0;
  }

  .aboutFond .redContent {
    width: 100%;
    padding: 15px;
    margin: 15px 0;
  }

  .aboutFond .redContent p {
    margin: 0 0 8px;
  }

  .aboutFond .redContent form .inputBlock input[type=text] {
    display: table;
    width: 100%;
    margin: 0 0 10px;
  }

  .aboutFond .redContent form .inputBlock span {
  }

  .aboutFond .redContent form .inputBlock label {
    padding: 10px 20px;
    margin: 0 10px 10px 0;
  }

  .aboutFond .redContent form .inputBlock:last-child label {
    margin-bottom: 10px;
  }

  .aboutFond .redContent form .inputBlock {
    margin: 10px 0 0;
  }

  .aboutFond .redContent form .btn-default.red {
    display: table;
    padding: 10px;
    margin-top: 5px;
    text-align: left;
  }

  .aboutFond .redContent form .btn-default.red img {
    display: inline-table;
    height: 24px;
  }
  .aboutFond .redContent form .inputBlock input[type=text] {
    font-size: 14px;
  }

  .aboutFond .redContent form .inputBlock label {
    font-size: 14px;
  }
  .aboutFond .redContent form .inputBlock input[type=text] {
    font-size: 14px;
  }

  .aboutFond .redContent form .inputBlock label {
    font-size: 14px;
  }

  .aboutFond .grayContent {
    width: 100%;
    margin: 15px 0 0;
    display: none;
  }

  .aboutFond .grayContent p span {
    font-weight: 500;
  }
  .aboutFond .bottomContent {
    padding-top: 20px;
  }

  .aboutFond .bottomContent h3 {
    margin: 0 0 15px;
  }

  .aboutFond .bottomContent .map {
    width: 100%;
    height: 120px;
    margin: 0;
  }

  .aboutFond .bottomContent .galleryBlock {
    width: 100%;
    margin: 0;
  }

  .aboutFond .bottomContent .galleryBlock .col-sm-3 {
    padding: 0 15px;
  }
  .accountMenu .container {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .accountMenu ul li {
    margin: 0 0 10px;
    width: 33%;
  }

  .accountMenu ul li a {
    padding: 8px;
    background: #E6F0FA;
    border-radius: 3px;
    line-height: 1;
    font-size: 14px;
    text-align: center;
    width: 95%;
  }

  .accountMenu {
    border-bottom: none;
  }

  .myOrganizationContent .container {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .myOrganizationContent .greyInfoBlock.firstBig {
    padding: 15px;
  }
  .myOrganizationContent .greyInfoBlock.firstBig img.logotype {
    width: 30%;
    display: table;
    margin: 0;
    object-fit: contain;
    height: 70px;
  }

  .myOrganizationContent .greyInfoBlock.firstBig h1 {
    font-size: 14px;
    margin: 6px 0 15px;
  }
  .myOrganizationContent .greyInfoBlock.firstBig h1 {
    position: absolute;
    right: 0;
    top: -60px;
    width: 64%;
  }

  .myOrganizationContent .greyInfoBlock.firstBig p.descr {
    margin: 15px 0 0;
    font-weight: 500;
  }

  .myOrganizationContent .greyInfoBlock.firstBig p.descr span {
    font-weight: 500;
    text-decoration: underline;
  }

  .myOrganizationContent .settings {
    margin: 0 0 10px;
    float: right;
    font-size: 14px;
  }

  .myOrganizationContent .settings img {
    height: 20px;
  }

  .myOrganizationContent .greyInfoBlock.mini {
    padding: 10px;
    background: transparent;
    margin: 0;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock {
    background: #fcfcff;
    padding: 10px;
    border-radius: 3px;
    border: none;
    margin: 0 0 10px;
  }

  .myOrganizationContent .greyInfoBlock .countTag {
    padding: 6px;
    font-size: 14px;
    margin: 0 5px 10px 0;
  }

  .myOrganizationContent .greyInfoBlock .countTag span {
    font-size: 14px;
    margin-left: 5px;
    padding: 5px 13px;
  }

  .myOrganizationContent .greyInfoBlock.mini .btn-default {
    font-size: 14px;
    padding: 10px 10px;
    display: inline-table;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock .tags.default.blue {
    padding: 6px;
    display: inline-table;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock p.name {
    margin: 20px 0 0;
    display: inline-table;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock p.mini.red {
    display: inline-table;
    margin: 0 0 0 5px;
    padding: 6px 10px;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock .btn-default {
    display: inline-table;
    width: 45%;
    margin-top: 18px;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock .btn-default:first-child {
    margin-right: 10px;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock p.mini.grey {
    display: inline-table;
    margin-left: 5px;
    padding: 6px 10px;
  }

  .myOrganizationContent .greyInfoBlock .btn-default.more {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock p.mini.green {
    display: inline-table;
    padding: 6px 10px;
    margin-left: 5px;
  }

  .myOrganizationContent .greyContent {
    display: none;
  }
  .organInfoBlock img.logotype.d-block {
    display: inline-table!important;
    width: 40%;
    margin: 0;
  }

  .organInfoBlock.projectPage .container {
    width: 100%;
    margin: 0;
  }

  .projectPage h1 {
    display: inline-table;
    position: relative;
    width: 59%;
    padding: 0 0 0 15px;
    margin: 0;
    vertical-align: middle;
    top: unset;
  }

  .projectPage .projectInfo {
    margin: 15px 0 0;
    padding: 15px 15px 0;
  }

  .projectPage .projectInfo .absol {
    display: none;
  }

  .projectPage .projectInfo p a {
    font-weight: 400;
  }

  .projectPage .ptojectContent {
    padding: 0;
    background: transparent;
  }

  .projectPage .ptojectContent p.descr {
    width: 100%;
    padding: 10px 15px;
    background: #FCFCFF;
    border-radius: 4px;
    max-width: 100%;
  }

  .organInfoBlock img.logotype {
    display: none;
  }

  .projectPage .allReviews {
    width: 100%;
    margin: 15px 0 0;
    text-align: center;
  }

  .projectPage .galleryBlock {
    width: 100%;
    margin: 0;
  }

  .projectPage .galleryBlock h3 {
    font-size: 16px;
  }
  .projectPage .galleryBlock .col-sm-2.col-6 {
    display: none;
  }

  .projectPage .galleryBlock .col-sm-2.col-6:first-child, .projectPage .galleryBlock .col-sm-2.col-6:last-child {
    display: table;
  }
  .aboutFond .bottomContent .galleryBlock .col-sm-3.col-6{
    display: none;
  }
  .aboutFond .bottomContent .galleryBlock .col-sm-3.col-6:first-child, .aboutFond .bottomContent .galleryBlock .col-sm-3.col-6:last-child {
    display: table;
  }
  .accountMenu h1 {
    display: none;
  }

  .accountMenu ul.myAccountMenu li {
    width: 50%;
  }

  .accountMenu ul.myAccountMenu li a {
    border: none;
    color: #333;
    font-weight: 500;
  }

  .myOrganizationContent .greyInfoBlock {
    padding: 10px;
  }
  .myAccountPage .accountInfo p.name {
    margin: 10px 0;
    font-size: 14px;
  }

  .myAccountPage .accountInfo p.descr {
    font-size: 12px;
  }

  .myAccountPage .accountInfo img.avatar {
    height: 100px;
  }

  .myOrganizationContent.myAccountPage .greyInfoBlock .applicationBlock {
    background: #F8F8FF;
    padding: 10px 15px;
  }

  .myOrganizationContent.myAccountPage .greyInfoBlock .applicationBlock p {
    display: inline-table;
    margin: 0 2px 10px 0;
  }

  .myOrganizationContent.myAccountPage .greyInfoBlock .applicationBlock .btn-default {
    margin: 5px 0 10px;
  }

  .myAccountPage .greyInfoBlock.mini a.settings {
    display: table;
    float: unset;
    margin: 0 0 10px;
  }
  .myApplicationsPage .greyInfoBlock.mini {
    padding: 24px 0 0;
  }

  .myApplicationsPage .greyInfoBlock.mini .countTag.grey {
    width: 100%;
  }

  .myApplicationsPage .greyInfoBlock.mini .countTag.green {width: 47%;}

  .myApplicationsPage .greyInfoBlock.mini .countTag.red {
    position: absolute;
    top: 24px;
    right: 0;
    margin-right: 0;
    width: 49%;
  }

  .myOrganizationContent .greyInfoBlock .applicationBlock .padLeft {
    padding-left: 15px;
  }
}